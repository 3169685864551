<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row align="center" justify="center">
          <h5 class="title">Detalhes do Bolsista</h5>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
          <v-text-field
            outlined
            dense
            label="Bolsista"
            disabled
            v-model="scholarship.studentName"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="space-between" pa0 ma0>
          <v-col cols="3"
            ><v-text-field
              outlined
              dense
              label="Curso"
              disabled
              v-model="scholarship.courseName"
            ></v-text-field
          ></v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="Início da bolsa"
              disabled
              v-model="scholarship.begin"
            ></v-text-field
          ></v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="Término da bolsa"
              disabled
              v-model="scholarship.end"
            ></v-text-field
          ></v-col>
        </v-row>

        <v-row>
           <v-col cols="12">
          <v-textarea
            outlined
            dense
            label="Observação"
            disabled
            v-model="scholarship.annotation"
          ></v-textarea>
           </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-btn outlined color="green" @click="closeDialog">VOLTAR</v-btn>
        </v-row>
      </v-card-actions>
      <br>
    </v-card>
  </v-container>
</template>

<script>
import { formatDate } from "@/utils/formatDate";

export default {
  name: "ScholarshipQuotaDetails",
  props: ["item"],
  data() {
    return {
      scholarship: {
        studentName: "",
        courseName: "",
        begin: "",
        end: "",
        annotation: "",
      },
    };
  },

  created() {
    this.scholarship.courseName = this.item.courseName;
    this.scholarship.annotation = this.item.annotation;
    this.scholarship.end = formatDate.dmy(this.item.end);
    this.scholarship.studentName = this.item.studentName;
    this.scholarship.begin = formatDate.dmy(this.item.begin);
  },

  computed: {},

  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
  watch: {
    item(newValue) {
      this.scholarship.courseName = newValue.courseName;
      this.scholarship.annotation = newValue.annotation;
      this.scholarship.end = formatDate.dmy(newValue.end);
      this.scholarship.studentName = newValue.studentName;
      this.scholarship.begin = formatDate.dmy(newValue.begin);
    },
  },
};
</script>

<style>
</style>
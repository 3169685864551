var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-flex", [_c("h3", [_vm._v("Cotas")])]),
      _vm._l(_vm.items, function(course) {
        return _c(
          "v-flex",
          { key: course.id, attrs: { "mt-5": "" } },
          [_c("ScholarshipQuotaComponent", { attrs: { course: course } })],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
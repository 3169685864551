<template>
  <v-container fluid @keypress.esc="closeDialog()">
    <v-card>
      <v-card-title class="justify-center"><h3>Remover a cota {{ quota.id }}?</h3></v-card-title>
      <v-card-text class="text-center">
        <p>
          <strong>Atenção!</strong> Esse procedimento removerá juntamente com a cota todas as
          bolsas de estudos a ela associada!
        </p>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6" class="text-center">
            <v-btn outlined color="green" @click="delAsyncSholarshipQuota(quota.id)">Apagar</v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn outlined color="red" @click="closeDialog()"
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ScholarshipQuotaDelDialog",
  props: ["quota"],

  data() {
    return {
      item: this.quota,
      disabled: false,
    };
  },

  created() {},

  methods: {
    ...mapActions("scholarshipQuota", ["ActionDelScholarshipQuota"]),

    async delAsyncSholarshipQuota(id) {
      try {
        if (id) {
          this.disabled = true;
          await this.ActionDelScholarshipQuota(id);
          this.closeDialog();
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.disabled = false;
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style>
</style>
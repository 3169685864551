<template>
  <v-container fluid>
    <v-card color="grey lighten-4">
      <v-card-title>
        <v-row class="ml-3">
          <v-col class="scholarshipQuotaTitle">
            <h5>Cotas de {{ course.name }}</h5>
            <v-text-field
              dense
              v-model="search"
              append-icon="search"
              placeholder="Buscar cota"
            ></v-text-field>
          </v-col>
          <v-col class="scholarshipQuotaTitle">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  x-small
                  color="green"
                  @click="dialog = true"
                  ><v-icon small>add</v-icon></v-btn
                >
              </template>
              <span>Adicionar uma nova cota</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading-text="loadingText"
        :no-data-text="noDataText"
        :items-per-page="10"
        :loading="loading"
        :search="search"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">{{ props.item.id }}</td>
            <td class="text-xs-left">{{ props.item.agency.name }}</td>
            <td class="text-xs-left">{{ props.item.value }}</td>
            <td class="text-xs-left">{{ props.item.student }}</td>
            <td class="text-xs-left">
              <div v-if="props.item.student != null">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      color="black"
                      @click="openDetails(props.item.scholarship)"
                      ><v-icon>visibility</v-icon></v-btn
                    >
                  </template>
                  <span>Detalhes da cota {{ props.item.id }}</span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      color="black"
                      disabled
                      @click="openDetails(props.item.scholarship)"
                      ><v-icon>visibility</v-icon></v-btn
                    >
                  </template>
                  <span>Detalhes da cota {{ props.item.id }}</span>
                </v-tooltip>
              </div>
            </td>
            <td class="text-xs-left">{{ props.item.createdAt }}</td>
            <td class="text-xs-left">{{ props.item.user }}</td>
            <td class="text-xs-left">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    text
                    color="green"
                    :disabled="!props.item.active"
                    @click="openDeactivateDialog(props.item)"
                    ><v-icon>flag</v-icon></v-btn
                  >
                </template>
                <span>Desativar a cota {{ props.item.id }}</span>
              </v-tooltip>
            </td>
            <td class="text-xs-left">
              <v-avatar
                v-if="props.item.allocated"
                color="green"
                size="20"
              ></v-avatar>
              <v-avatar v-else color="grey" size="20"></v-avatar>
            </td>
            <td class="text-xs-left">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    x-small
                    color="orange"
                    @click="openUpdateDialog(props.item)"
                    ><v-icon small>edit</v-icon></v-btn
                  >
                </template>
                <span>Editar a cota {{ props.item.id }}</span>
              </v-tooltip>
            </td>
            <td class="text-xs-left">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    x-small
                    color="red"
                    @click="openDelDialog(props.item)"
                    ><v-icon small>delete</v-icon></v-btn
                  >
                </template>
                <span>Remover a cota {{ props.item.id }}</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" max-width="800" @keydown.esc="dialog = false">
      <ScholarshipQuotaDialog
        v-on:close-dialog="closeDialog"
        :course="course"
      ></ScholarshipQuotaDialog>
    </v-dialog>

    <v-dialog v-model="details" max-width="650" @keydown.esc="details = false">
      <ScholarshipQuotaDetails
        v-on:close-dialog="closeDetails"
        :item="scholarship"
      ></ScholarshipQuotaDetails>
    </v-dialog>

    <v-dialog
      v-model="dialogDel"
      max-width="800"
      @keydown.esc="dialogDel = false"
    >
      <ScholarshipQuotaDelDialog
        v-on:close-dialog="closeDelDialog"
        :quota="quota"
      ></ScholarshipQuotaDelDialog>
    </v-dialog>

    <v-dialog
      v-model="dialogUpdate"
      max-width="600"
      @keydown.esc="dialogUpdate = false"
    >
      <ScholarshipQuotaUpdateDialog
        v-on:close-dialog="closeUpdateDialog"
        :item="quota"
      ></ScholarshipQuotaUpdateDialog>
    </v-dialog>

    <v-dialog
      v-model="dialogDeactivate"
      max-width="600"
      @keydown.esc="dialogDeactivate = false"
    >
      <ScholarshipQuotaDeactivate
        v-on:close-dialog="closeDeactivateDialog()"
        :quota="quota"
      ></ScholarshipQuotaDeactivate>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatDate } from "@/utils/formatDate";
import ScholarshipQuotaDialog from "./ScholarshipQuotaDialog";
import ScholarshipQuotaDetails from "./ScholarshipQuotaDetails";
import ScholarshipQuotaDelDialog from "./ScholarshipQuotaDelDialog";
import ScholarshipQuotaDeactivate from "./ScholarshipQuotaDeactivate";
import ScholarshipQuotaUpdateDialog from "./ScholarshipQuotaUpdateDialog";

export default {
  name: "ScholarshipQuotaComponent",
  props: ["course"],
  components: {
    ScholarshipQuotaDialog,
    ScholarshipQuotaDetails,
    ScholarshipQuotaDelDialog,
    ScholarshipQuotaDeactivate,
    ScholarshipQuotaUpdateDialog,
  },
  data() {
    return {
      headers: [
        { sortable: true, text: "Código", value: "id", align: "left" },
        { sortable: true, text: "Agência", value: "agency", align: "left" },
        { sortable: true, text: "Valor R$", value: "value", align: "left" },
        { sortable: true, text: "Bolsista", value: "student", align: "left" },
        { sortable: true, text: "Detalhes", value: "", align: "left" },
        {
          sortable: true,
          text: "Criado em",
          value: "createdAt",
          align: "left",
        },
        { sortable: true, text: "Criado por", value: "user", align: "left" },
        { sortable: true, text: "Ativo", value: "active", align: "left" },
        { sortable: true, text: "Alocada", value: "allocated", align: "left" },
        { sortable: false, text: "Editar", value: "edit", align: "left" },
        { sortable: false, text: "Remover", value: "remove", align: "left" },
      ],
      items: [],
      quota: {},
      search: "",
      loadingText: "Buscando cotas de " + this.course.name,
      noDataText: "Não foram encontradas cotas de " + this.course.name,
      dialog: false,
      details: false,
      dialogDel: false,
      dialogUpdate: false,
      dialogDeactivate: false,
      scholarship: {},
      disabled: false,
      loading: true,
    };
  },

  created() {
    //if (this.scholarshipQuotas.length == 0) {
    this.getAsyncActionGetScholarShipQuotas();
    // } else {
    //   this.uploadDataTable();
    //  }
  },

  computed: {
    ...mapState("scholarshipQuota", ["scholarshipQuotas"]),
  },

  methods: {
    ...mapActions("scholarshipQuota", [
      "ActionGetScholarshipQuotas",
      "ActionPutStatusScholarshipQuota",
    ]),

    async getAsyncActionGetScholarShipQuotas() {
      try {
        this.loading = true;
        await this.ActionGetScholarshipQuotas();
        this.uploadDataTable();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    closeDialog() {
      this.dialog = false;
    },

    closeDetails() {
      this.details = false;
    },

    openDelDialog(item) {
      this.quota = item;
      this.dialogDel = true;
    },

    closeDelDialog() {
      (this.quota = {}), (this.dialogDel = false);
    },

    openUpdateDialog(item) {
      this.quota = item;
      this.dialogUpdate = true;
    },

    closeUpdateDialog() {
      (this.quota = {}), (this.dialogUpdate = false);
    },

    openDeactivateDialog(item) {
      this.quota = item;
      this.dialogDeactivate = true;
    },

    closeDeactivateDialog() {
      this.dialogDeactivate = false;
    },

    openDetails(item) {
      this.scholarship = item;
      this.details = true;
    },

    uploadDataTable() {
      this.items = [];
      this.scholarshipQuotas
        .filter((e) => e.courseCode === this.course.code)
        .map((e) => {
          this.items.push({
            id: e.id,
            value: e.value,
            user: e.userName,
            active: e.active,
            allocated: e.allocated,
            agency: e.fundingAgencyTO,
            scholarship: e.scholarshipActive,
            createdAt: formatDate.dmy(e.createdAt),
            student: e.scholarshipActive.studentName,
          });
        });
    },

    async asyncPutActivateScholarshipQuota(id) {
      try {
        this.disabled = true;
        await this.ActionPutStatusScholarshipQuota(id);
      } catch (e) {
        console.error(e);
      } finally {
        this.disabled = false;
      }
    },
  },

  watch: {
    scholarshipQuotas(newValue) {
      this.items = [];
      newValue
        .filter((e) => e.courseCode === this.course.code)
        .map((e) => {
          this.items.push({
            id: e.id,
            value: e.value,
            user: e.userName,
            active: e.active,
            allocated: e.allocated,
            agency: e.fundingAgencyTO,
            scholarship: e.scholarshipActive,
            createdAt: formatDate.dmy(e.createdAt),
            student: e.scholarshipActive.studentName,
          });
        });
    },
  },
};
</script>

<style scoped>
.scholarshipQuotaTitle {
  margin: 0%;
  padding: 0%;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-row", { attrs: { align: "center", justify: "center" } }, [
                _c("h5", { staticClass: "title" }, [
                  _vm._v("Detalhes do Bolsista")
                ])
              ])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Bolsista",
                          disabled: ""
                        },
                        model: {
                          value: _vm.scholarship.studentName,
                          callback: function($$v) {
                            _vm.$set(_vm.scholarship, "studentName", $$v)
                          },
                          expression: "scholarship.studentName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "space-between", pa0: "", ma0: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Curso",
                          disabled: ""
                        },
                        model: {
                          value: _vm.scholarship.courseName,
                          callback: function($$v) {
                            _vm.$set(_vm.scholarship, "courseName", $$v)
                          },
                          expression: "scholarship.courseName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Início da bolsa",
                          disabled: ""
                        },
                        model: {
                          value: _vm.scholarship.begin,
                          callback: function($$v) {
                            _vm.$set(_vm.scholarship, "begin", $$v)
                          },
                          expression: "scholarship.begin"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Término da bolsa",
                          disabled: ""
                        },
                        model: {
                          value: _vm.scholarship.end,
                          callback: function($$v) {
                            _vm.$set(_vm.scholarship, "end", $$v)
                          },
                          expression: "scholarship.end"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Observação",
                          disabled: ""
                        },
                        model: {
                          value: _vm.scholarship.annotation,
                          callback: function($$v) {
                            _vm.$set(_vm.scholarship, "annotation", $$v)
                          },
                          expression: "scholarship.annotation"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "green" },
                      on: { click: _vm.closeDialog }
                    },
                    [_vm._v("VOLTAR")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("br")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
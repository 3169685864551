<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h5>Inserir nova cota</h5>
      </v-card-title>
      <v-card-text>
        <v-row
          ><v-select
            class="ml-3 mr-3"
            outlined
            prepend-inner-icon="business"
            no-data-text="Não foram encontradas agências"
            v-model="quota.body.fundingAgencyId"
            :items="fundingAgencies"
            :item-text="'name'"
            :item-value="'id'"
            label="Agência"
            :rules="[valueRules.notNull]"
            :disabled="disabled"
            @keypress.enter="postAsyncQuota()"
          >
            Agência</v-select
          ></v-row
        >
        <v-row>
          <v-text-field
            prepend-inner-icon="attach_money"
            outlined
            class="ml-3 mr-5"
            label="Valor"
            v-model="quota.body.value"
            :rules="[valueRules.format, valueRules.format2]"
            :disabled="disabled"
            @keypress.enter="postAsyncQuota()"
          ></v-text-field>
          <v-text-field
            prepend-inner-icon="school"
            type="number"
            outlined
            class="ml-5 mr-3"
            label="Quantidade de cotas"
            v-model="quota.quantity"
            :rules="[valueRules.major]"
            :disabled="disabled"
            @keypress.enter="postAsyncQuota()"
          ></v-text-field>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="green"
              v-show="disabled == true"
            ></v-progress-circular>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn outlined color="red" class="mr-3 mb-3" @click="closeDialog()"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              outlined
              color="green"
              class="ml-3 mr-3 mb-3"
              @click="postAsyncQuota()"
              >Adicionar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatText } from "@/utils/formatField";

export default {
  name: "ScholarshipQuotaDialog",
  props: ['course'],
  data() {
    return {
      agencies: [],
      currentCourses: [],
      disabled: false,
      quota: {
        quantity: 0,
        body: {
          fundingAgencyId: 0,
          courseCode: this.course.code,
          value: 0,
        },
      },
      valueRules: {
        format: (v) =>
          formatText.fieldToFloat(v) || "O valor está mal formatado!",
        format2: (v) => formatText.isFloat(v) || "O valor está mal formatado!",
        major: (v) =>
          formatText.majorZero(v) || "O valor deve ser maior que zero!",
        notNull: (v) => v != 0 || "Escolha uma opção!",
      },
    };
  },

  created() {
    if (this.fundingAgencies.length == 0) {
      this.getAsyncFundingAgencies();
    }
  },

  computed: {
    ...mapState("fundingAgency", ["fundingAgencies"]),
  },

  methods: {
    ...mapActions("fundingAgency", ["ActionGetFundingAgencies"]),
    ...mapActions("scholarshipQuota", ["ActionPostScholarshipQuotasByQuantity"]),

    async getAsyncFundingAgencies() {
      try {
        this.disabled = true;
        await this.ActionGetFundingAgencies();
        this.disabled = false;
      } catch (err) {
        console.log(err);
      }
    },

    async postAsyncQuota() {
      try {
        if (
          this.quota.body.fundingAgencyId > 0 &&
          this.quota.body.courseCode != 0 &&
          formatText.isFloat(this.quota.body.value) &&
          this.quota.quantity > 0
        ) {
          this.disabled = true;
          await this.ActionPostScholarshipQuotasByQuantity(this.quota);
          this.disabled = false;
          this.closeDialog();
        }
      } catch (e) {
        console.error(e);
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style>
</style>
<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="justify-center">
        <h3>Atualizar a cota {{ item.id }}</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              dense
              class="ml-3 mr-3"
              outlined
              prepend-inner-icon="business"
              no-data-text="Não foram encontradas agências"
              v-model="quota.fundingAgencyId"
              :items="fundingAgencies"
              item-text="name"
              item-value="id"
              label="Agência de Fomento"
              :rules="[valueRules.notNull]"
              :disabled="disabled"
              @keypress.enter="putAsyncQuota()"
            >
              Agência</v-select
            >
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              prepend-inner-icon="attach_money"
              outlined
              class="ml-3 mr-5"
              label="Valor"
              v-model="quota.value"
              :rules="[valueRules.format, valueRules.format2]"
              :disabled="disabled"
              @keypress.enter="putAsyncQuota()"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              dense
              label="Ativa"
              v-model="quota.active"
              color="grey"
              class="ma-1 pa-0"            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="green"
              v-show="disabled == true"
            ></v-progress-circular>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn outlined color="red" class="mr-3 mb-3" @click="closeDialog()"
              >Cancelar</v-btn
            >
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              outlined
              color="green"
              class="ml-3 mr-3 mb-3"
              @click="putAsyncQuota()"
              >Atualizar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatText } from "@/utils/formatField";

export default {
  name: "ScholarshipQuotaUpdateDialog",
  props: ["item"],
  data() {
    return {
      disabled: false,
      quota: {
        value: 0,
        fundingAgencyId: 0,
        active: false,
      },
      valueRules: {
        format: (v) =>
          formatText.fieldToFloat(v) || "O valor está mal formatado!",
        format2: (v) => formatText.isFloat(v) || "O valor está mal formatado!",
        major: (v) =>
          formatText.majorZero(v) || "O valor deve ser maior que zero!",
        notNull: (v) => v != 0 || "Escolha uma opção!",
      },
    };
  },

  created() {
    if (this.fundingAgencies.length == 0) {
      this.getAsyncFundingAgencies();
    } else {
      this.quota.id = this.item.id;
      this.quota.fundingAgencyId = this.item.agency.id;
      this.quota.value = this.item.value;
      this.quota.active = this.item.active;
      this.quota.allocated = this.item.allocated;
    }
  },

  computed: {
    ...mapState("fundingAgency", ["fundingAgencies"]),
  },

  methods: {
    ...mapActions("fundingAgency", ["ActionGetFundingAgencies"]),
    ...mapActions("scholarshipQuota", ["ActionPutScholarshipQuota"]),

    async getAsyncFundingAgencies() {
      try {
        this.disabled = true;
        await this.ActionGetFundingAgencies();
        this.quota.id = this.item.id;
        this.quota.fundingAgencyId = this.item.agency.id;
        this.quota.value = this.item.value;
        this.quota.active = this.item.active;
        this.quota.allocated = this.item.allocated;
      } catch (err) {
        console.log(err);
      } finally {
        this.disabled = false;
      }
    },

    async putAsyncQuota() {
      try {
        this.disabled = true;
        await this.ActionPutScholarshipQuota(this.quota);
        this.disabled = false;
        this.closeDialog();
      } catch (e) {
        console.error(e);
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
      this.disabled = false;
    },
  },

  watch: {
    item(newItem) {
      if (newItem != null && newItem.id != null) {
        this.quota.id = newItem.id;
        this.quota.fundingAgencyId = newItem.agency.id;
        this.quota.value = newItem.value;
        this.quota.allocated = newItem.allocated;
        this.quota.active = newItem.active;
      } else {
        this.quota = {};
      }
    },
  },
};
</script>

<style>
</style>
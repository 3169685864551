var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { attrs: { color: "grey lighten-4" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { staticClass: "ml-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "scholarshipQuotaTitle" },
                    [
                      _c("h5", [_vm._v("Cotas de " + _vm._s(_vm.course.name))]),
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "append-icon": "search",
                          placeholder: "Buscar cota"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "scholarshipQuotaTitle" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            outlined: "",
                                            "x-small": "",
                                            color: "green"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.dialog = true
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("add")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Adicionar uma nova cota")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              "loading-text": _vm.loadingText,
              "no-data-text": _vm.noDataText,
              "items-per-page": 10,
              loading: _vm.loading,
              search: _vm.search
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c("tr", [
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.id))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.agency.name))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.value))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.student))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        props.item.student != null
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        "x-small": "",
                                                        text: "",
                                                        color: "black"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openDetails(
                                                            props.item
                                                              .scholarship
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("visibility")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Detalhes da cota " +
                                          _vm._s(props.item.id)
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        "x-small": "",
                                                        text: "",
                                                        color: "black",
                                                        disabled: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openDetails(
                                                            props.item
                                                              .scholarship
                                                          )
                                                        }
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("visibility")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Detalhes da cota " +
                                          _vm._s(props.item.id)
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.createdAt))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.user))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  text: "",
                                                  color: "green",
                                                  disabled: !props.item.active
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openDeactivateDialog(
                                                      props.item
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_c("v-icon", [_vm._v("flag")])],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Desativar a cota " + _vm._s(props.item.id)
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          props.item.allocated
                            ? _c("v-avatar", {
                                attrs: { color: "green", size: "20" }
                              })
                            : _c("v-avatar", {
                                attrs: { color: "grey", size: "20" }
                              })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  outlined: "",
                                                  "x-small": "",
                                                  color: "orange"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openUpdateDialog(
                                                      props.item
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("edit")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v("Editar a cota " + _vm._s(props.item.id))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  outlined: "",
                                                  "x-small": "",
                                                  color: "red"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openDelDialog(
                                                      props.item
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("delete")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Remover a cota " + _vm._s(props.item.id)
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialog = false
            }
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("ScholarshipQuotaDialog", {
            attrs: { course: _vm.course },
            on: { "close-dialog": _vm.closeDialog }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "650" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.details = false
            }
          },
          model: {
            value: _vm.details,
            callback: function($$v) {
              _vm.details = $$v
            },
            expression: "details"
          }
        },
        [
          _c("ScholarshipQuotaDetails", {
            attrs: { item: _vm.scholarship },
            on: { "close-dialog": _vm.closeDetails }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialogDel = false
            }
          },
          model: {
            value: _vm.dialogDel,
            callback: function($$v) {
              _vm.dialogDel = $$v
            },
            expression: "dialogDel"
          }
        },
        [
          _c("ScholarshipQuotaDelDialog", {
            attrs: { quota: _vm.quota },
            on: { "close-dialog": _vm.closeDelDialog }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialogUpdate = false
            }
          },
          model: {
            value: _vm.dialogUpdate,
            callback: function($$v) {
              _vm.dialogUpdate = $$v
            },
            expression: "dialogUpdate"
          }
        },
        [
          _c("ScholarshipQuotaUpdateDialog", {
            attrs: { item: _vm.quota },
            on: { "close-dialog": _vm.closeUpdateDialog }
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialogDeactivate = false
            }
          },
          model: {
            value: _vm.dialogDeactivate,
            callback: function($$v) {
              _vm.dialogDeactivate = $$v
            },
            expression: "dialogDeactivate"
          }
        },
        [
          _c("ScholarshipQuotaDeactivate", {
            attrs: { quota: _vm.quota },
            on: {
              "close-dialog": function($event) {
                return _vm.closeDeactivateDialog()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
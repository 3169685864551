var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      attrs: { fluid: "" },
      on: {
        keypress: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeDialog()
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "justify-center" }, [
            _c("h3", [_vm._v("Remover a cota " + _vm._s(_vm.quota.id) + "?")])
          ]),
          _c("v-card-text", { staticClass: "text-center" }, [
            _c("p", [
              _c("strong", [_vm._v("Atenção!")]),
              _vm._v(
                " Esse procedimento removerá juntamente com a cota todas as bolsas de estudos a ela associada! "
              )
            ])
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", color: "green" },
                          on: {
                            click: function($event) {
                              return _vm.delAsyncSholarshipQuota(_vm.quota.id)
                            }
                          }
                        },
                        [_vm._v("Apagar")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", color: "red" },
                          on: {
                            click: function($event) {
                              return _vm.closeDialog()
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-container fluid @keypress.esc="closeDialog()">
    <v-card>
      <v-card-title class="justify-center">
        <h4>Desativar a bolsa?</h4>
      </v-card-title>
      <v-card-text class="text-center">
        <p><strong>Atenção!</strong> Esse procedimento irá desativar a cota!</p>
        <p>Tem certeza que deseja desativar a cota {{ quota.id }}?</p>
        <p v-if="scholarship != null && scholarship.studentName != null">
          <strong>Atenção!!!</strong>
          Essa ação irá remover a bolsa do aluno(a)
          <strong>{{ scholarship.studentName }}!!</strong>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-progress-linear
              indeterminate
              color="green"
              v-show="disabled"
            ></v-progress-linear>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              outlined
              color="green"
              :disabled="disabled"
              @click="asynDeactivateScholarshipQuota()"
              >Desativar</v-btn
            >
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              outlined
              color="red"
              :disabled="disabled"
              @click="closeDialog()"
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ScholarshipDeactivate",
  props: ["quota"],
  data() {
    return {
      disabled: false,
      scholarships: [],
      scholarship: {},
    };
  },

  created() {
    this.asyncGetScholarshipByQuotaId(this.quota.id);
  },

  methods: {
    ...mapActions("scholarshipQuota", ["ActionPutStatusScholarshipQuota"]),
    ...mapActions("scholarship", ["ActionGetScholarshipsByQuotaId"]),

    async asynDeactivateScholarshipQuota() {
      try {
        this.disabled = true;
        await this.ActionPutStatusScholarshipQuota(this.quota.id);
        this.closeDialog();
      } catch (e) {
        console.error(
          "Erro ao desativar a bolsa do aluno " + this.scholarship.student,
          e
        );
      } finally {
        this.disabled = false;
      }
    },

    async asyncGetScholarshipByQuotaId(id) {
      try {
        this.disabled = true;
        this.scholarship = {};
        const res = await this.ActionGetScholarshipsByQuotaId(id);
        this.scholarships = res.body;
        if (this.scholarships.length > 0)
          this.scholarship = this.scholarships.find((e) => e.active);
      } catch (e) {
        console.error(e);
      } finally {
        this.disabled = false;
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
    },
  },

  watch: {
    quota(newQuota) {
      this.asyncGetScholarshipByQuotaId(newQuota.id);
    },
  },
};
</script>

<style>
</style>
/**
 *      Utilitário para formatar as datas
 */
export const formatText = {
    fieldToFloat: (value) => {
        if (value === null) return '';
        let regex = new RegExp(/^[0-9]{0,6}.?[0-9]{1,2}$/);
        return regex.test(value)
    },

    majorZero: (value) => {
        if (value === null) return false;
        let regex = new RegExp(/^[0-9]{0,3}$/);
        return regex.test(value)
    },

    isFloat: (value) => {
        let v = parseFloat(value);
        if (Number.isNaN(v)) {
            return false;
        } else {
            return true;
        }
    },
    minor2000: (value) => {
        if (value)
            return value.length <= 2000;
        return true;
    },
    minor200: (value) => {
        if (value)
            return value.length <= 200;
        return true;
    },
    minor100: (value) => {
        if (value)
            return value.length <= 100;
        return true;
    },
}

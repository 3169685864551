var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_c("h5", [_vm._v("Inserir nova cota")])]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-select",
                    {
                      staticClass: "ml-3 mr-3",
                      attrs: {
                        outlined: "",
                        "prepend-inner-icon": "business",
                        "no-data-text": "Não foram encontradas agências",
                        items: _vm.fundingAgencies,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Agência",
                        rules: [_vm.valueRules.notNull],
                        disabled: _vm.disabled
                      },
                      on: {
                        keypress: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.postAsyncQuota()
                        }
                      },
                      model: {
                        value: _vm.quota.body.fundingAgencyId,
                        callback: function($$v) {
                          _vm.$set(_vm.quota.body, "fundingAgencyId", $$v)
                        },
                        expression: "quota.body.fundingAgencyId"
                      }
                    },
                    [_vm._v(" Agência")]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-text-field", {
                    staticClass: "ml-3 mr-5",
                    attrs: {
                      "prepend-inner-icon": "attach_money",
                      outlined: "",
                      label: "Valor",
                      rules: [_vm.valueRules.format, _vm.valueRules.format2],
                      disabled: _vm.disabled
                    },
                    on: {
                      keypress: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.postAsyncQuota()
                      }
                    },
                    model: {
                      value: _vm.quota.body.value,
                      callback: function($$v) {
                        _vm.$set(_vm.quota.body, "value", $$v)
                      },
                      expression: "quota.body.value"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "ml-5 mr-3",
                    attrs: {
                      "prepend-inner-icon": "school",
                      type: "number",
                      outlined: "",
                      label: "Quantidade de cotas",
                      rules: [_vm.valueRules.major],
                      disabled: _vm.disabled
                    },
                    on: {
                      keypress: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.postAsyncQuota()
                      }
                    },
                    model: {
                      value: _vm.quota.quantity,
                      callback: function($$v) {
                        _vm.$set(_vm.quota, "quantity", $$v)
                      },
                      expression: "quota.quantity"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("v-progress-circular", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.disabled == true,
                            expression: "disabled == true"
                          }
                        ],
                        attrs: { indeterminate: "", color: "green" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3 mb-3",
                          attrs: { outlined: "", color: "red" },
                          on: {
                            click: function($event) {
                              return _vm.closeDialog()
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3 mr-3 mb-3",
                          attrs: { outlined: "", color: "green" },
                          on: {
                            click: function($event) {
                              return _vm.postAsyncQuota()
                            }
                          }
                        },
                        [_vm._v("Adicionar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
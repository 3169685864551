<template>
  <v-container fluid>
    <v-flex>
      <h3>Cotas</h3>
    </v-flex>
    <v-flex v-for="course in items" :key="course.id" mt-5>
      <ScholarshipQuotaComponent :course="course"></ScholarshipQuotaComponent>
    </v-flex>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ScholarshipQuotaComponent from "./ScholarshipQuotaComponent";

export default {
  name: "ScholarshipQuota",
  props: [],
  components: {
    ScholarshipQuotaComponent
  },
  data() {
    return {
      headers: [
        { sortable: true, text: "Código", value: "code", align: "left" },
        { sortable: true, text: "Agência", value: "agency", align: "left" },
        { sortable: true, text: "Tipo", value: "type", align: "left" },
        { sortable: true, text: "Valor R$", value: "value", align: "left" },
        {
          sortable: true,
          text: "Criado em",
          value: "createdAt",
          align: "left",
        },
        { sortable: true, text: "Criado por", value: "user", align: "left" },
      ],
      items: [],
    };
  },

  created() {
    this.getAsyncCourses();
  },

  computed: {
    ...mapState('course', ['courses'])
  },

  methods: {
    ...mapActions('course', ['ActionGetCoursesByCollegeCode']),
    async getAsyncCourses(){
      try{
        await this.ActionGetCoursesByCollegeCode('IC');
      } catch (e){
        console.error(e);
      }
    }
  },

  watch: {
    courses(newValue){
      if(newValue){
        this.items = []
        this.items = newValue.filter(c => c.code != "99");
      }
    }
  }
}
</script>

<style>
</style>